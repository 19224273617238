<template>
  <div class="order">
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="未报价" name="first"></el-tab-pane>
          <el-tab-pane label="已报价" name="second"></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="tableData">
          <el-table-column label="品名" prop="name"></el-table-column>
          <el-table-column label="数量" prop="quantity"></el-table-column>
          <el-table-column label="地区要求" prop="origin"></el-table-column>
          <el-table-column label="渠道" prop="resourceChannel"></el-table-column>
          <el-table-column
            v-if="activeName=='first'"
            key="goPrice"
            label="操作"
            show-overflow-tooltip
          >
            <template slot-scope="scope" v-if="activeName=='first'">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
              >我要报价</el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeName=='second'"
            key="cancelPrice"
            label="操作"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="cancelPriceOrder(scope.row)">撤销报价单</el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="activeName=='second'"
            key="orderPrice"
            label="我的报价"
            prop="orderPrice"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="paginationOrder">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="我的报价" :visible.sync="dialogVisible" width="30%" center>
      <el-input v-model="priceNumber" placeholder="请输入报价"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="goWritePrice()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  mounted() {
    this.param_buyerOrder();
  },
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      buying: [],
      bought: [],
      activeName: "first",
      tableData: [],
      dialogVisible: false,
      priceNumber: null,
      priceItem: null
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.activeName == "first") {
        this.param_buyerOrder();
      } else {
        this.param_buyerOrderOfferDetail();
      }
    },
    handleCurrentChange(val) {
      this.current = val;
      if (this.activeName == "first") {
        this.param_buyerOrder();
      } else {
        this.param_buyerOrderOfferDetail();
      }
    },
    handleClick() {
      this.square = null;
      this.current = 1;
      this.pageSize = 10;
      if (this.activeName == "first") {
        this.param_buyerOrder();
      } else {
        this.param_buyerOrderOfferDetail();
      }
    },
    param_buyerOrderOfferDetail() {
      protocolFwd.param_buyerOrderOfferDetail.param.page = this.current - 1;
      protocolFwd.param_buyerOrderOfferDetail.param.size = this.pageSize;
      http.postFront(protocolFwd.param_buyerOrderOfferDetail).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    param_buyerOrder() {
      protocolFwd.param_buyerOrder.param.page = this.current - 1;
      protocolFwd.param_buyerOrder.param.size = this.pageSize;
      http.postFront(protocolFwd.param_buyerOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleDelete(index, item) {
      this.dialogVisible = true;
      this.priceItem = item;
    },
    goWritePrice() {
      if (window.sessionStorage.getItem("userType") == 2) {
        protocolFwd.param_offerOrder.param.bsFlag = "B";
      } else if (window.sessionStorage.getItem("userType") == 3) {
        protocolFwd.param_offerOrder.param.bsFlag = "S";
      }
      protocolFwd.param_offerOrder.param.commodityId = this.priceItem.commodityId;
      protocolFwd.param_offerOrder.param.orderId = this.priceItem.id;
      protocolFwd.param_offerOrder.param.price = this.priceNumber;
      protocolFwd.param_offerOrder.param.quantity = this.priceItem.quantity;
      http.postFront(protocolFwd.param_offerOrder).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.dialogVisible = false;
          this.$EL_MESSAGE(message);
        } else {
          this.dialogVisible = false;
          this.$EL_MESSAGE(message);
        }
      });
    },
    cancelPriceOrder(item) {
      this.$confirm("确认撤销报价单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_changeOfferOrder.param.status = 3;
          protocolFwd.param_changeOfferOrder.param.offerId = item.id;
          http.postFront(protocolFwd.param_changeOfferOrder).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.param_buyerOrderOfferDetail();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.order {
  padding: 0 20px;
}
.paginationOrder {
  margin-top: 15px;
}
</style>
